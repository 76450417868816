import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders single accordion item, it uses two slot elements to handle title and body of an
 * accordion item. This element is related to the accordion-element.
 */
class AccordionItem extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-open'
    ];
  }

  readonly dataset!: {
    /**
     * The dom id of the wrapper element within the shadow dom of this node
     *
     * @todo refactor because caller code should never be directly concerned with element ids within
     * the shadow dom
     */
    itemId: string;

    /**
     * Whether this item is in the expanded state
     */
    open: 'false' | 'true';
  };

  public shadowRoot!: ShadowRoot;
  private onLabelClickBound = this.onLabelClick.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const id = `faq-body-${this.dataset.itemId}`;

    const label = this.shadowRoot.querySelector<HTMLElement>('.item-label');
    label.setAttribute('aria-controls', id);
    label.addEventListener('click', this.onLabelClickBound);

    const wrapper = this.shadowRoot.querySelector('.item-body-wrapper');
    wrapper.id = id;

    this.render();
  }

  public disconnectedCallback() {
    const label = this.shadowRoot.querySelector<HTMLElement>('.item-label');
    label?.removeEventListener('click', this.onLabelClickBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const label = this.shadowRoot.querySelector('.item-label');
    const body = this.shadowRoot.querySelector('.item-body-wrapper');

    if (this.dataset.open === 'true') {
      label?.setAttribute('aria-expanded', 'true');
      body?.removeAttribute('aria-hidden');
    } else {
      label?.setAttribute('aria-expanded', 'false');
      body?.setAttribute('aria-hidden', 'true');
    }
  }

  private onLabelClick(_event: MouseEvent) {
    // If the content of the text is less than 300px in height, hide the scrollbar in order to avoid
    // the appearing and disappearing act when the accordion grows.
    const itemBodyWrapper = this.shadowRoot.querySelector('.item-body-wrapper');
    const itemBody = this.shadowRoot.querySelector('.item-body');
    const scrollHeight = itemBody.scrollHeight;

    if (scrollHeight <= 300) {
      itemBodyWrapper.classList.add('hide-scrollbar');
    }

    const body = this.querySelector('[slot="body"]');
    if (!body) {
      return;
    }

    const label = this.querySelector('[slot="label"]');
    if (!label) {
      return;
    }

    const eventType = this.dataset.open === 'true' ?
      'accordion-item-collapsed' :
      'accordion-item-expanded';

    type Detail = AccordionItemCollapsedEvent['detail'] | AccordionItemExpandedEvent['detail'];
    const changeEvent = new CustomEvent<Detail>(eventType, {
      cancelable: false,
      detail: {
        body: body.innerHTML,
        itemId: this.dataset.itemId,
        title: label.innerHTML
      }
    });

    this.dispatchEvent(changeEvent);
  }
}

interface AccordionItemChangedDetail {
  /**
   * The inner html of the accordion item element
   */
  body: string;

  /**
   * The item id of the accordion item element (not the element id)
   */
  itemId: string;

  /**
   * The inner html of the accordion item title
   */
  title: string;
}

/**
 * Fired after an accordion item is expanded.
 */
type AccordionItemExpandedEvent = CustomEvent<AccordionItemChangedDetail>;

/**
 * Fired after an accordion item is collapsed.
 */
type AccordionItemCollapsedEvent = CustomEvent<AccordionItemChangedDetail>;

declare global {
  interface HTMLElementEventMap {
    'accordion-item-collapsed': AccordionItemCollapsedEvent;
    'accordion-item-expanded': AccordionItemExpandedEvent;
  }

  interface HTMLElementTagNameMap {
    'accordion-item': AccordionItem;
  }
}

function onDOMContentLoaded(_event?: Event) {
  if (!customElements.get('accordion-item')) {
    customElements.define('accordion-item', AccordionItem);
  }
}

// This is loaded via a script tag with the async script attribute. Wait for the DOM to be loaded
// before defining the custom element.

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  onDOMContentLoaded();
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
