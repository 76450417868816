import type { CustomElement } from '@integrabeauty/custom-elements';
import styles from './index.scss';

/**
 * Renders accordion wrapper, it handles basic events for accordion items. This element uses
 * accordion-item elements to render each item.
 */
class AccordionElement extends HTMLElement implements CustomElement {
  public shadowRoot!: ShadowRoot;

  private onItemExpandedBound = this.onItemExpanded.bind(this);
  private onItemClosedBound = this.onItemClosed.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style><slot></slot>`;
  }

  public connectedCallback() {
    const items = this.querySelectorAll('accordion-item');
    for (const item of items) {
      item.addEventListener('accordion-item-expanded', this.onItemExpandedBound);
      item.addEventListener('accordion-item-collapsed', this.onItemClosedBound);
    }
  }

  public disconnectedCallback() {
    const items = this.querySelectorAll('accordion-item');
    for (const item of items) {
      item.removeEventListener('accordion-item-expanded', this.onItemExpandedBound);
      item.removeEventListener('accordion-item-collapsed', this.onItemClosedBound);
    }
  }

  private onItemExpanded(event: HTMLElementEventMap['accordion-item-expanded']) {
    const item = this.querySelector<HTMLElementTagNameMap['accordion-item']>(
      `accordion-item[data-item-id="${event.detail.itemId}"]`);
    if (item) {
      // This conditional statement prevents collapsing all "accordion-item" elements from different
      // "accordion-element"s.
      const items = this.querySelectorAll('accordion-item');
      for (const item of items) {
        item.dataset.open = item.dataset.itemId === event.detail.itemId ? 'true' : 'false';
      }
    }
  }

  private onItemClosed(event: HTMLElementEventMap['accordion-item-collapsed']) {
    const selector = `accordion-item[data-item-id="${event.detail.itemId}"]`;
    const item = this.querySelector<HTMLElementTagNameMap['accordion-item']>(selector);
    if (item) {
      item.dataset.open = 'false';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'accordion-element': AccordionElement;
  }
}

function onDOMContentLoaded(_event?: Event) {
  if (!customElements.get('accordion-element')) {
    customElements.define('accordion-element', AccordionElement);
  }
}

// This is loaded via a script tag with the async script attribute. Wait for the DOM to be loaded
// before defining the custom element.

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  onDOMContentLoaded();
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
